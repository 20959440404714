.terms-wrapper {
	margin-top: 0px;
	text-align: center;
	margin-bottom: 0px;
	background-image: linear-gradient(to bottom, #ffffff, #e5f1ff);
		h3 {
			padding-top: 136px;
			text-align: center;
			font-size: 40px;
			line-height: 1.28;
			color: rgba(51, 51, 51, 0.87);
			margin-bottom: 0px;
		}
		.subtitles {
			padding-top: 40px;
			font-size: 20px;
			text-align: start;
			font-weight: bold;
			color: rgba(51, 51, 51, 0.87);
			margin-left: 70px;
			margin-bottom: 0px;
		}
		.section_titles {
			padding-top: 10px;
			font-size: 16px;
			text-align: start;
			font-weight: bold;
			color: rgba(51, 51, 51, 0.87);
			margin-left: 70px;
			margin-bottom: 0px;
		}
		.paragraghs {
			padding-top: 10px;
			font-size: 14px;
			text-align: start;
			color: rgba(51, 51, 51, 0.87);
			margin-left: 70px;
			margin-right: 70px;
			margin-bottom: 0px;
			padding-bottom: 10px;
		}
}

