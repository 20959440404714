.pricing-wrapper {
    margin-top: 0px;
    text-align: center;
    margin-bottom: 0px;
    background-image: linear-gradient(to bottom, #ffffff, #e5f1ff);
    h2 {
        padding-top: 136px;
        text-align: center;
        font-size: 50px;
        line-height: 1.28;
        color: rgba(51, 51, 51, 0.87);
        margin-bottom: 0px;
    }
    & > p {
        font-size: 16px;
        line-height: 1.5;
        color: rgba(51, 51, 51, 0.6);
        margin-top: 16px;
    }
    .pricing-plans {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 20px;
        .pricing-card {
            box-shadow: 0px 0px 4px rgba(205, 216, 229, 0.25), 0px 0px 4px rgba(205, 216, 229, 0.25);
            background: white;
            padding: 40px 20px;
            text-align: left;
            position: relative;
            .pricing-card-title {
                // font-family: Ubuntu;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #505d68;
                text-align: left;
            }
            .pricing-details {
                display: flex;
                align-items: baseline;
            }
            .pricing-details,
            .pricing-details-enterprise {
                border-bottom: 2px solid #e5f1ff;
                padding-bottom: 30px;
                margin-bottom: 20px;
            }
            .pricing-details-enterprise {
                border-radius: 4px;
                color: rgba(51, 51, 51, 0.6);
                padding-bottom: 28px !important;
                span {
                    span {
                        color: #2d8dfc;
                        font-weight: bold;
                        font-size: 20px;
                    }
                }
            }
            .price {
                font-style: normal;
                font-weight: bold;
                font-size: 38px;
                line-height: 48px;
                color: #2d8dfc;
                padding-right: 8px;
            }
            .pricing-unit {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                color: rgba(51, 51, 51, 0.6);

                .contact-us {
                    text-transform: capitalize;
                    cursor: pointer;
                    transition: color 300ms ease-in-out;

                    &:hover {
                        color: #2d8dfc;
                    }
                }
            }
            .plan-features {
                list-style-type: none;
                text-align: left;
                margin: 0px;
                padding: 0px;
                li {
                    text-align: left;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 21px;
                    color: rgba(51, 51, 51, 0.6);
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-bottom: 8px;

                    img {
                        flex: 0 0 auto;
                    }
                }
                li.feature-na {
                    img {
                        visibility: hidden;
                    }
                    color: rgba(51, 51, 51, 0.2);
                    // opacity: 0
                }
                button {
                    width: 100%;
                    height: 42px;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #2d8dfc;
                    background-color: white;
                    font-size: 12px;
                    font-weight: bold;
                    letter-spacing: 0.5px;
                    cursor: pointer;
                    text-transform: uppercase;
                    margin-top: 35px;
                    border: 1px solid #2d8dfc;
                }
                button.inverse {
                    background-color: #2d8dfc;
                    color: white;
                }
            }
            .popular {
                position: absolute;
                top: 12px;
                right: 5px;
                font-size: 11px;
                background: #2d8dfc;
                color: white;
                font-weight: bold;
                padding: 1px 3px;
                border-radius: 10px;
            }
        }
        .most-popular {
            box-shadow: 0px 4px 12px rgba(23, 74, 133, 0.25), 0px 0px 12px rgba(23, 74, 133, 0.25);
        }
    }
    h3.stay_in_touch {
        font-size: 38px;
        padding-top: 100px;
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 31px;
        text-align: center;
        color: rgba(51, 51, 51, 0.87);
    }
    button.btn_contact {
        width: 10rem;
        height: 42px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #2d8dfc;
        background-color: white;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.5px;
        cursor: pointer;
        text-transform: uppercase;
        margin-top: 35px;
        margin-bottom: 2rem;
        border: 1px solid #2d8dfc;
    }
    h2.faq_heading {
        font-size: 38px;
        padding: 60px 0px;
    }
    .faq {
        border: 1px solid #c2d1d9;
        background: white;
        border-radius: 2px;
        padding: 1rem;
        display: flex;
        align-items: end;
        .Collapsible {
            cursor: pointer;
            display: block;
            width: 100%;
            justify-content: space-between;
            text-align: left;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #505d68;
            align-items: baseline;
            p {
                font-weight: normal;
                margin-top: 1rem;
                margin-bottom: 0rem;
            }
            i {
                color: #2d8dfc;
            }
            span.is-open {
                display: block;
                width: 100%;
                // background: url("../../assets/images/icons/chevronr.svg") no-repeat top
                //   right;
            }
            span.is-open ~ img {
                display: none;
                // background: url("../../assets/images/icons/chevronb.svg") no-repeat top
                //   right;
            }
            span.is-closed {
                display: block;
                width: 100%;
                // background: url("../../assets/images/icons/chevronb.svg") no-repeat top
                //   right;
            }
        }
        .answer {
            margin-top: 1rem;
            text-align: justify;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #505d68;
            padding-right: 2rem;
            display: none;
        }
    }
    .stay_in_touch_div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .all-article-btn {
        width: 264px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 24px auto;
        border-radius: 4px;
        border: solid 1px #63a7f5;
        background-color: #ffffff;
        color: #2d8dfc;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1.1px;
        cursor: pointer;
    }
}

.form-footer {
    display: flex;
    justify-content: center;
}

.contactus-pricing {
    .contact-wrapper {
        margin-top: 0px;
        p {
            margin-bottom: 0px;
        }
        .contact-form-wrapper {
            box-shadow: none;
        }
        .contact-form-wrapper {
            height: unset;
            width: 100% !important;
        }
    }
}
@media (max-width: 1200px) {
    .pricing-wrapper {
        .pricing-card {
            .pricing-details {
                .price {
                    font-size: 22px;
                }
                .pricing-unit {
                    font-size: 12px;
                }
            }
        }
    }
}
@media only screen and(min-width:768px) and (max-width: 1000px) {
    .pricing-wrapper {
        .container {
            padding: 0;
        }
        .pricing-plans {
            .col-md-3 {
                padding: 0 5px;
            }
            .pricing-card {
                padding: 40px 5px;
                .price {
                    font-size: 22px;
                }
                .pricing-unit {
                    font-size: 12px;
                }
                .pricing-details-enterprise {
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
@media (min-width: 1000px) {
    .pricing-details-enterprise {
        span {
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .pricing-wrapper {
        h2 {
            font-size: 38px;
            padding-left: 10%;
            padding-right: 10%;
        }
        p {
            padding-left: 10%;
            padding-right: 10%;
        }
        .faq {
            margin-top: 10px;
        }
        .pricing-plans {
            padding: 0 10%;
            margin-top: 50px;
            grid-template-columns: 1fr;
            grid-row-gap: 20px;
            .col-md-3 {
                margin-bottom: 20px;
            }
            .pricing-card {
                box-shadow: none;
                .popular {
                    text-align: right;
                    color: white;
                    font-size: 11px;
                    span {
                        background-color: #2d8dfc;
                        border-radius: 10px;
                        padding: 2px 4px;
                        font-weight: bold;
                    }
                }
                li.feature-na {
                    color: rgba(51, 51, 51, 0.2);
                    // opacity: 0
                    display: none;
                }
            }
        }
        .faq-wrapper {
            .mt-3 {
                margin-top: 0px !important;
            }
        }
        h2.faq_heading {
            padding: 70px 0px 40px;
        }
        h3.stay_in_touch {
            padding-top: 70px;
        }
        button.btn_contact {
            margin-top: 20px;
        }
    }
    .contactus-pricing {
        .contact-form-wrapper {
            padding: 5px 20px 40px;
            label {
                margin-bottom: 0;
            }
            .form-group {
                margin-bottom: 0.5rem !important;
            }
        }
    }
}
.cursor-pointer {
    cursor: pointer;
}

.pull-right {
    float: right;
}

.two-pricings {
    display: inline-block !important;
    text-align: right;
    line-height: normal;
}

.pricing-wrapper .pricing-plans .pricing-card .two-pricings {
    border-bottom: 2px solid #e5f1ff;
    padding-bottom: 10px;
    width: fit-content;
}

@media only screen and(min-width:768px) and (max-width: 989px) {
    .container {
        max-width: 740px;
    }
    .two-pricings {
        text-align: left;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 29px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #0275d8;
}

input:focus + .slider {
    box-shadow: 0 0 1px #0275d8;
}

input:checked + .slider:before {
    -webkit-transform: translateX(21px);
    -ms-transform: translateX(21px);
    transform: translateX(21px);
}

.slider.round {
    border-radius: 29px;
}

.slider.round:before {
    border-radius: 50%;
}

.team-size-info {
    color: #000000 !important;
    margin: 2rem auto 0 auto !important;   
}

.starter-info {
    position: absolute;
    width: 50%;
    top: 10px;
    right: 10px;
    font-size: .67rem;
    padding: 5px;
    background: rgba(205, 216, 229, 0.25);
}