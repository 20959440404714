.contact_us_wrapper {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 0px;
    background-image: linear-gradient(to bottom, #ffffff, #e5f1ff);
    
    & > p {
      font-size: 16px;
      line-height: 1.5;
      color: rgba(51, 51, 51, 0.6);
      margin-top: 16px;
    }

    h3.stay_in_touch {
      font-size: 38px;
      padding-top: 100px;
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 31px;
      text-align: center;
      color: rgba(51, 51, 51, 0.87);
    }

    button.btn_contact {
      width: 10rem;
      height: 42px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #2d8dfc;
      background-color: white;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.5px;
      cursor: pointer;
      text-transform: uppercase;
      margin-top: 35px;
      margin-bottom: 2rem;
      border: 1px solid #2d8dfc;
    }

    .stay_in_touch_div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }  
  }
  
  .contactus-pricing {
    .contact-wrapper {
      margin-top: 0px;
      p {
        margin-bottom: 0px;
      }
      .contact-form-wrapper {
        box-shadow: none;
      }
      .contact-form-wrapper {
        height: unset;
        width: 100% !important;
      }
    }
  }
  
  @media only screen and (max-width: 767px) {
    .contact_us_wrapper {
      margin-top: 7%;
      p {
        padding-left: 10%;
        padding-right: 10%;
      }
  
      h3.stay_in_touch {
        padding-top: 70px;
      }
      button.btn_contact {
        margin-top: 20px;
      }
    }
    .contactus-pricing {
      .contact-form-wrapper {
        padding: 5px 20px 40px;
        label {
          margin-bottom: 0;
        }
        .form-group {
          margin-bottom: 0.5rem !important;
        }
      }
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  
  .pull-right {
    float: right;
  }

  @media only screen and (min-width:200px) and (max-width: 768px) {
    .contact_us_wrapper {
      margin-top: 20%;
    }
  }