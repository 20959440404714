.trustby-wrapper {
    margin-top: 100px;
    h2 {
      text-align: center;
      font-size: 50px;
      line-height: 1.28;
      color: rgba(51, 51, 51, 0.87);
    }
    .trusty-brand {
      margin: 32px auto;
      text-align: center;
      & > div {
        display: inline-block;
        vertical-align: middle;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .trusty-brand-item {
        width: 30%;
        height: 168px;
        // margin-left: 10px;
      }
    }
    .trusty-feedback {
      margin-top: 80px;
      padding: 0 auto;
      position: relative;
      display: grid;
      grid-column-gap: 10px;
      grid-template-columns: 1fr 1fr;
      padding: 0 8%;
      .feedback-quote {
        color: white;
        font-size: 110px;
        position: absolute;
        left: 9%;
        bottom: -50px;
        z-index: 9997;
      }
      .trusty-feedback-item {
        display: inline-block;
        vertical-align: middle;
        // width: 40%;
        // height: 339px;
        border-radius: 2px;
        background-color: #e6f1ff;
        padding: 0 20px 32px 20px;
        text-align: center;
        box-shadow: #00000025 0px 20px 15px;
        .feedback-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          // height: 236px;
          margin-top: 10%;
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 1.48;
            color: rgba(51, 51, 51, 0.87);
          }
          .comma {
            margin: 10px;
          }
        }
        span {
          font-size: 16px;
          line-height: 1.5;
          color: rgba(51, 51, 51, 0.87);
          display: block;
        }
        label {
          margin-top: 12px;
          font-size: 18px;
          font-weight: bold;
          color: rgba(51, 51, 51, 0.87);
        }
      }
      & > div:nth-child(1) {
        // margin-left: calc(50% - 565px);
        // margin-left: 10%;
      }
      & > div:nth-child(2) {
        margin-left: 30px;
      }
    }
  }
  @media screen and(max-width:767px) {
    .trustby-wrapper {
      .trusty-brand {
        display: flex;
        align-items: center;
        flex-direction: column;
        .trusty-brand-item {
          width: 100%;
        }
      }
      .trusty-feedback {
        grid-row-gap: 15%;
        margin-top: 0;
        grid-template-columns: auto;
        .feedback-quote {
          top: 40%;
          bottom: unset;
        }
      }
      .trusty-feedback > div:nth-child(2) {
        margin-left: 0;
      }
    }
  }