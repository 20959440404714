.benefits-section {
  text-align: center;
  margin: 0 auto;
  margin-top: 81px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  padding: 10px;
  padding: 0 13%;
  .benefits-item {
    padding: 20px;
    padding-top: 0;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    background-image: linear-gradient(to top, #ffffff, #fafafa);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    div {
      display: flex;
      height: 77px;
      justify-content: center;
      align-items: center;
      img {
        object-fit: cover;
        display: block;
        margin: 0 auto;
      }
    }
    p {
      margin-top: 33px;
      text-align: left;
      display: block;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      color: rgba(51, 51, 51, 0.87);
      text-align: center
    }
    span {
      margin-top: 5px;
      text-align: left;
      display: block;
      font-size: 14px;
      line-height: 1.5;
      color: rgba(51, 51, 51, 0.6);
    }
  }
  .benefits-item:nth-child(2) {
    margin-left: 5%;
  }
  .side-arrow {
    margin: 0 20px;
    display: flex;
    align-items: center
  }
}


@media screen and(max-width:767px){
  .benefits-section{
    grid-template-rows:auto auto auto;
    grid-template-columns:unset;
    row-gap: 30px;
   .side-arrow{
     display: none
   }
   .benefits-item{
     p{
       text-align: center
     }
   }
  }
}
