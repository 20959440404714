.modal-wrapper {
  z-index: 10000 !important;
  margin-top: 2.5%;
  color: #424b5a;
  .content {
    padding: 0% 5%;
    text-align: center;
    margin-bottom: 30px;
    h2 {
      margin: 10px 0 30px;
    }
  }
  .form-content {
    padding: 0 30%;
    .optional {
      font-size: 12px;
      font-style: italic;
      color: grey;
      margin-left: 2px;
    }
  }
  .form-label {
    margin-bottom: 0px !important;
    font-size: 15px;
  }
  .form-group {
    margin-bottom: 0rem !important;
  }
  .modal-dialog {
    margin-bottom: 10%;
  }
  .button-container {
    text-align: center;
    // margin: 25px 0;
    button {
      width: 40%;
      padding: 10px;
    }
  }
  .modal-header {
    justify-content: flex-end;
    background-color: #fafafa;
    .modal-title {
      font-size: 18px;
      // color: black;
      .fa {
        margin-left: 10px;
      }
    }
  }
  .dropdown-item {
    span {
      margin-left: 10px;
    }
  }
  .dropdown {
    .code {
      margin-left: 10px;
    }
  }
  .error {
    color: red;
  }

  .subdomain-input {
    display: flex;
    align-items: center;
  }
}
.modal-backdrop {
  z-index: 10000 !important;
}
.react-tel-input {
  #phone-form-control {
    width: 100%;
  }
}
// #input-group-dropdown-1{
.code-dropdown-menu .dropdown-menu {
  height: 200px;
  overflow-y: scroll;
  font-size: 12px;
}
// }
#input-group-dropdown-1 {
  padding: 0px 5px;
  z-index: inherit;
}
.country-flag {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.try-free-modal {
  margin-bottom: 4rem;
}

@media only screen and (max-width: 600px) {
  .modal-wrapper {
    .form-content {
      padding: 0 5%;
    }
    .button-container {
      margin: 0 5%;
      button {
        width: 100%;
      }
    }
  }
  .code-dropdown-menu .dropdown-menu {
    width: 85vw;
    a {
      padding: 0.25rem 0;
    }
  }

  .try-free-modal {
    margin-bottom: 5rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

