.howworks-container {
  margin-top: 7%;
  h2 {
    text-align: center;
    font-size: 50px;
    line-height: 1.28;
    color: rgba(51, 51, 51, 0.87);
    text-transform: none;
  }
  .howworks-item {
    display: flex;
    & > div {
      display: inline-block;
      vertical-align: top;
    }
    .howworks-text-wrapper {
      margin-top: 81px;
      img {
        width: 25px;
        height: 25px;
        display: inline-block;
        margin-top: 33px;
      }
      .howwork-item-textgroup {
        display: inline-block;
        vertical-align: top;
        margin-left: 16px;
        max-width: 450px;
        .description {
          display: flex;
          img {
            margin-right: 10px;
          }
        }
        .content {
          margin-left: 35px;
          color: grey;
          font-size: 15px;
          margin-right: 10px;
        }
        p {
          font-size: 28px;
          line-height: 1.36;
          color: #2d8dfc;
          margin-top: 24px;
        }
        span {
          font-size: 16px;
          line-height: 1.5;
          color: rgba(51, 51, 51, 0.6);
        }
      }
    }
    .idex-player-container {
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 100;
      position: relative;
      max-width: 800px;
    }
  }
  .howworks-item.align-left {
    margin-left: 10%;
    .sample-img-wrapper {
      float: right;
    }
  }
  .howworks-item.align-right {
    margin-left: 0;
    .howworks-text-wrapper {
      margin-right: 10%;
      float: right;
    }
  }
  .item-ideate {
    margin-top: 64px;
  }
  .item-engage {
    background: #fafafa;
    padding: 75px 0;
    margin-top: 80px;
    position: relative;
    flex-direction: row-reverse;
    .vote-up-img {
      position: absolute;
      width: 120px;
      height: 120px;
      top: 15px;
      left: 150px;
      z-index: 0;
    }
  }
  .item-evaluate {
    margin-top: 100px;
  }
}

@media screen and(max-width:767px) {
  .howworks-container {
    margin-top: 15%;
    .howworks-item {
      flex-direction: column;
      .howworks-text-wrapper {
        margin-top: 0;
        margin-bottom: 8%;
      }
      .sample-img-wrapper {
        margin: 0 5%;
      }
    }
    .howworks-item.align-left {
      margin-left: 0;
    }
    .item-ideate {
      margin-top: 0px;
    }
    .item-engage {
      background-color: none;
      padding: 0;
      .vote-up-img {
        display: none;
      }
    }
    .item-evaluate {
      margin-top: 10%;
    }
  }
}
