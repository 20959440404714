.trial-footer {
  margin-top: auto;
}

.trial-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

