.ideaMgnt-container {
    padding-top: 180px;
    text-align: center;
    margin-bottom: 0px;
    background-image: linear-gradient(to bottom, #e5f1ff, #ffffff);
  
    .ideaMgnt-header-text {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
  
      h2 {
        font-weight:normal;
        font-size: 38px;
        line-height: 1.26;
        color: rgba(51, 51, 51, 0.87);
      }
  
      p {
        padding-top: 21px;
        padding-left: 20%;
        padding-right: 20%;
        height: 93px;
        font-size: 16px;
        line-height: 1.48;
        color: rgba(51, 51, 51, 0.6);
      }
    }
  
    .ideaMgnt-item {
      margin-top: 90px;
  
      .ideaMgnt-text-wrapper {
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
  
        h2 {
          font-size: 25px;
          font-weight: normal;
          line-height: 1.36;
          color: #111827;
          padding: 0 10px 0 10px
        }
  
        .ideas-description {
          font-size: 15px;
          line-height: 1.5;
          color: #6b7280;
          padding: 0 10px 0 10px
        }

        .ideas-overview-description {
            font-size: 15px;
            line-height: 1.5;
            color: #6b7280;
            padding: 0 10px 0 10px
        }
      }
    
      .ideaMgnt-image-container {
        img {
          max-width: 100%;
          object-fit: contain;
        }  
      }  
    }
  
    .ideaMgnt-item.reverse {
      display: flex;
      flex-direction: row-reverse;
    }
  }
  
  @media screen and (max-width: 767px) {
    .ideaMgnt-container {
      padding-top: 150px;
    
      .ideaMgnt-header-text {
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
    
        p {
          padding-left: 10%;
          padding-right: 10%;
        }
      }
    
      .ideaMgnt-item {
        margin-top: 90px;
        display: flex;
        flex-direction: column-reverse;
    
        .ideaMgnt-text-wrapper {
          text-align: center;
          padding: 3% 15% 0 15%;
        }
      
        .ideaMgnt-image-container {
          img {
            max-width: 70%;
          }  
        }  
      }
    
      .ideaMgnt-item.reverse {
        flex-direction: column-reverse;
      }
    }
  }