.trial-wrapper {
  padding-top: 60px;
  
  .grey-background {
      background-color: #333333;
      margin-top:50px;
    
    .trial-text-wrapper {
      position: relative;
      font-family: sans-serif;
      color: #fff;
      font-size: 36px;
      padding: 4% 0;

      .free-trial-button {
        width: 35%;
        height: 42px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background-color:#2d8dfc;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.5px;
        cursor: pointer;
        text-transform: uppercase;
        margin-top: 35px;
        margin-bottom: 30px;        
        border: 1px solid #2d8dfc;
      }

      .trial-phrase {
        margin-top: 35px;        
        width: 100%;
        height: 24px;
        flex-grow: 0;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .trial-image {
      img {
        max-width: 100%;
        object-fit: contain;
      }  
    }
  }
}

@media screen and (max-width: 767px) {
  .trial-wrapper {    
    .grey-background {
      .trial-text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 1em;
        padding-right: 1em;
  
        .free-trial-button {
          margin-top: 10px;
          margin-bottom: 10px;  
        }

        .trial-phrase {
          text-align: center;
          margin-top: 20px; 
        }
      }
  
      .trial-image {
        display: none;
      }
    }
  }
}