.about-us-wrapper {
  margin-top: 80px;
  text-align: center;
  height: 80%;
  margin-bottom: 100px;
  background-image: linear-gradient(to bottom, #ffffff, #e5f1ff);
  
  .about-us-intro {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    margin: 80px 50px 0 50px;
    padding: 50px 50px 0 50px;

    .about-us-content {
      display: inline-block;
      margin-top: 50px;
      margin-right: 50px;
  
      .about-us-title {
        align-self: auto;
        text-align: left;
        font-size: 38px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.26;
        letter-spacing: normal;
        padding-bottom: 30px;
        color: rgba(51, 51, 51, 0.87);
      }
    
      .about-us-description {
        max-width: 537px;
        align-self: auto;
        text-align: left;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.26;
        letter-spacing: normal;
        color: rgba(51, 51, 51, 0.6);
      }
    }

    .about-us-graphic {
      display: inline-block;
      align-self: center;
      max-width: 40%;
    }
  }

  .core-values-wrapper {
    display: flex;
    flex-direction: column;
    margin: 100px 50px 0 50px;
    padding: 50px 50px 0 50px;
    background-color: #fff;

    .core-value-title {
      align-self: center;
      text-align: center;
      font-size: 38px;
      font-weight: normal;
      line-height: 1.26;
      color: rgba(51, 51, 51, 0.87);
      padding-bottom: 50px;
    }

    .core-values {
      align-self: center;
      display: flex;
      justify-content: flex-start;
      margin: auto;
      padding-bottom: 50px;
      text-align: left;      
    }
    
    .icons {
      max-width: 40px;
    }

    .value-content {
      align-self: flex-start;
      max-width: 450px;
  
      .value-title {
        margin: 10px 32px 10px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: rgba(51,51,51,.87);
      }
  
      .value-description {
        gap: 20px;
        margin: 10px 32px 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: #6b7280;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .about-us-wrapper {
    .about-us-intro {
      display: flex;
  
      .about-us-content {
        margin-top: 20px;
        margin-right: 0;
    
        .about-us-title {
          text-align: center;
        }
      }
  
      .about-us-graphic {
        display: none;
      }
    }

    .core-values-wrapper {
      display: flex;
      flex-direction: column;
      margin: 50px 50px 0 50px;
  
      .core-values {
        display: flex;  
        flex-direction: column; 
        margin-right: 0;  

        .values {
          display: flex;
          padding-bottom: 20px;

          .icons {
            align-self: flex-start;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .about-us-wrapper {
    .about-us-intro {
      margin: 80px 0px 0 0px;
  
      .about-us-graphic {
        display: none;
      }
    }

    .core-values-wrapper {
      display: flex;
      flex-direction: column;
      margin: 50px 0px 0 0px;
    }
  }
}
