.range-data-2 {
    padding-bottom: 40px;
    width: 65%;
    margin: 1rem auto;
    display: flex;
    &.hidden {
        display: none;
    }
    .range-holder {
        width: 100%;
        label {
            color: #333333;
            display: inline-block;
            font-size: 12px;
            font-weight: 200;
            margin: 0;
            margin-top: 2px;
        }
        input {
            border: 1px solid #e9e9e9;
            border-radius: 3px;
            padding-left: 7px;
        }
    }
}

.rc-slider-handle-1 {
    display: none;
}

.rc-slider-rail {
    height: 8px;
    border-radius: 20px;
    background: #c2d1d9;
}

.rc-slider-track {
    background: #75818f;
    height: 8px !important;
}

.rc-slider-dot:first-child,
.rc-slider-dot:last-child
 {
    display: none;
} 

.rc-slider-dot {
    bottom: -6px;
    height: 12px;
}

.rc-slider-handle:active {
    box-shadow: 0 0 0 5px #96dbfa !important;
    outline: none;
}

.rc-slider-handle {
    margin-left: 0px;
    margin-top: -6px;
    width: 18px;
    height: 18px;
}

.input-range__slider,
.rc-slider-handle {
    background: #2d8dfc !important;
    border: 1px solid #2d8dfc !important;
    transition: unset !important;
}
.input-range__track--active,
.rc-slider-track {
    background: #75818f !important;
    transition: unset !important;
}

.input-range__slider-container {
    transition: unset !important;
}

.filters-range {
    .range-title {
        color: black !important;
        cursor: pointer;
        font-size: 0.8rem;
        margin: 22px 0;
        text-transform: uppercase;
        margin-top: 0;
        padding-top: 22px;
        i {
            &.hidden {
                display: none;
            }
        }
    }
    .range-title.collapsed {
        padding-bottom: 20px;
        border-bottom: 1px solid #dddddd;
    }
    .range-data {
        padding-bottom: 20px;
        border-bottom: 1px solid #dddddd;
        &.hidden {
            display: none;
        }
        .range-holder {
            display: flex;
            justify-content: space-between;
            label {
                color: #333333;
                display: inline-block;
                font-size: 12px;
                font-weight: 200;
                margin: 0;
                margin-top: 2px;
            }
            input {
                border: 1px solid #e9e9e9;
                border-radius: 3px;
                max-width: 33%;
                padding-left: 7px;
            }
        }
        .rc-slider-track {
            background: #75818f;
            height: 8px !important;
        }
        .rc-slider-handle {
            background: #2d8dfc;
            border-color: #2d8dfc;
        }
    }
    .rc-slider-dot {
        position: absolute;
        bottom: -6px;
        margin-left: 0px;
        width: 1px;
        height: 12px;
        border: none;
        background-color: rgb(117, 129, 143);
        cursor: pointer;
        border-radius: 50%;
        vertical-align: middle;
        transition: unset !important;
    }
}
