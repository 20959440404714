.loader-wrapper {
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}

.loader-wrapper,
.loader-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-content .sk-spinner {
  position: relative;
}
.loader-message {
  font-size: 0.9em;
  margin-top: 11em;
}
