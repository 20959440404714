.footer-wrapper {
  .footer-content {
    .footer-hyperlinks {
      display: flex;
    }
    .footer-links {
      width: 100%;
      .footer-link-titles {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.5px;
        color: rgba(51, 51, 51, 0.87);
      }
      .footer-link {
        font-size: 12px;
        line-height: 1.75;
        color: rgba(51, 51, 51, 0.6);
        padding-bottom: 5px;
      }
      .links-display {
        display: flex;
        flex-direction: column;
      }
      .links-display a:hover {
        text-decoration: none;
      }
    }

    .footer-logo {
      text-align: center;
      img {
        width: 67px;
        margin-top: 32px;
        object-fit: cover;
      }
      .footer-address {
        width: 100%;
        p:nth-child(1) {
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.5px;
          color: rgba(51, 51, 51, 0.87);
        }
        p:nth-child(2) {
          font-size: 12px;
          line-height: 1.75;
          color: rgba(51, 51, 51, 0.6);
        }
      }
      .footer-social {
        ul {
          list-style: none;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          li {
            display: inline-block;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 20px;
            color: #babec2;
            a {
              margin-top: 22px;
              font-size: 17px;
              text-transform: none;
              text-decoration: none;
              color: #97a0ab;
            }
            a:hover {
              color: #2d8dfc;
            }
          }
          li:nth-child(1) {
            padding-left: 0;
          }
        }
      }
    }
    
    .footer-fn {
      text-align: right;
      padding-right: -10px;
      p {
        margin-top: 24px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        color: rgba(51, 51, 51, 0.87);
      }
      .footer-collect {
        font-size: 16px;
        line-height: 1.5;
        color: rgba(51, 51, 51, 0.87);
      }
      .footer-start-btn {
        margin-top: 20px;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1.5px;
        color: #ffffff;
        width: 167px;
        height: 40px;
        border-radius: 4px;
        background-color: #2d8dfc;
        text-transform: uppercase;
        cursor: pointer;
      }
      .footer-start-btn:hover {
        text-decoration: none;
      }
    }
  }

  .footer-line {
    border-top: 1px solid #c2d1d9;
  }

  .footer-copyright {
    font-size: 14px;
    line-height: 2.3px;
    color: rgba(51, 51, 51, 0.87);
    text-align: left;
    text-decoration: none;
  }
  .copyright-align {
    text-align: right;
  }
}

@media only screen and(max-width:767px) {
  .footer-wrapper {
    flex-direction: column-reverse;
    height: unset;
    .footer-content {
      height: auto;
      .footer-hyperlinks {
        display: none;
      }
    }
    .footer-fn {
      order: 3;
      text-align: center !important;
      padding: 5% 6%;
      .footer-start-btn {
        float: unset !important;
        margin: 24px auto;
      }
    }
    .copyright-align {
      text-align: center;
    }
  }
}
