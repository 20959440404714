.head-section {
  // height: 1100px;
  display: block;
  position: relative;
  .head-circle-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    max-width: 100%;
  }
  .linear-bg {
    height: 676px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -2;
    background-image: linear-gradient(
        to bottom,
        #ffffff,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(to bottom, #e5f1ff, #e5f1ff),
      linear-gradient(to bottom, #e5f1ff, #e5f1ff);
  }
  .head-notebook-img {
    position: absolute;
    top: 250px;
    right: 0px;
    z-index: -1;
  }
  h2 {
    padding-top: 176px;
    text-align: center;
    font-size: 50px;
    line-height: 1.28;
    color: rgba(51, 51, 51, 0.87);
  }
  p {
    text-align: center;
    margin-top: 32px;
    font-size: 21px;
    line-height: 1.48;
    color: rgba(51, 51, 51, 0.6);
  }
  .exp-idex-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 166px;
    height: 40px;
    border-radius: 4px;
    background-color: #2d8dfc;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #ffffff;
    margin: 0 auto;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 32px;
  }
  .exp-idex-btn:hover {
    text-decoration: none;
  }
  .idex-player-container {
    padding: 0 20%;
  }
  .idex-player {
    position: relative;
    margin: 0 auto;
    margin-top: 102px;
    // height: 400px;
    // width: 60%;
    background-color: white;
    box-shadow: #00000033 0px 10px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    .player-btn {
      width: 53px;
      height: 53px;
      background-color: #2d8dfc;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        color: white;
        font-size: 20px;
        margin-left: 5px;
      }
      i:hover {
        color: #2d8dfc;
      }
    }
    .player-btn:hover {
      background-color: white;
    }
    .play-icon {
      z-index: 10;
      width: 10%;
      cursor: pointer;
    }
    .image-hide {
      opacity: 0;
    }
    /* This used to work for the parent element of button divs */
    /* But it does not work with newer browsers, the below doesn't hide the play button parent div */
    // .react-player{
    //   position: unset;
    // }
  }
}
@media screen and(min-width:601px) and (max-width: 900px) {
  .head-section {
    .idex-player-container {
      padding: 10% 15%;
    }
  }
}
@media screen and(min-width:601px) and (max-width: 960px) {
  .head-notebook-img {
    display: none;
  }
}

@media screen and(min-width:520px) {
  .head-section {
    h2 {
      margin-left: 27.5%;
      max-width: 45%;
    }
    p {
      margin-left: 27.5%;
      max-width: 45%;
    }
  }
}

@media screen and(max-width:600px) {
  .head-section {
    h2 {
      font-size: 30px;
    }
    .idex-player-container {
      padding: 0 5%;
    }
    .head-notebook-img {
      width: 100%;
    }
    .idex-player {
      // height: 200px;
      margin-top: 250px;
    }
    .head-notebook-img {
      top: 350px;
    }
  }
}

.media-control-container {
  display: none !important;
}
