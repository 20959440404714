.header-wrapper {
    box-shadow: 0px 4px 20px rgba(201, 206, 213, 0.25);
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    height: 70px;
    width: 100%;
    background: white;
    z-index: 9999;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;

    & > div {
        justify-content: flex-end;
        align-items: center;
    }
    & > div:nth-child(1) {
        // padding-left: ;
    }

    .header-logo-wrapper {
        width: 85px;
        height: 70px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        img {
            width: 85px;
            height: 40px;
            cursor: pointer;
        }
    }
    .menu {
        display: flex;
        flex-grow: 1;
        .menu-group {
            height: 70px;
            display: flex;
            a.menu-item {
                text-decoration: none;
                text-transform: uppercase;
                margin-left: 15px;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 0.2px;
                color: rgba(51, 51, 51, 0.87);
                padding-left: 7px;
                padding-right: 7px;
                align-items: center;
                display: inline-block;
                margin-top: 28px;
                white-space: nowrap;
            }
            a.active::after {
                content: "";
                border-bottom: 3px solid #2d8dfc;
                z-index: 13;
                display: block;
                position: relative;
                margin-top: 22px;
                width: 117%;
                margin-left: -3px;
                border-radius: 4px;
            }
            a.active {
                color: #2d8dfc;
                background-image: linear-gradient(white, #f6faff, #f6faff);
            }
            a:hover {
                color: #2d8dfc;
                border-bottom: 3px #2d8dfc;
            }
        }
    }
    .lang-section {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        font-size: 12px;
        min-width: 110px;
        margin-left: 20px;
        .flag-select img {
            width: 18px;
            height: 12px;
            position: relative;
            top: 0;
        }
        .flag-select .country-flag .country-label {
            font-size: 12px;
            color: rgba(51, 51, 51, 0.87);
        }
        .country-label {
            font-size: 12px;
            color: rgba(51, 51, 51, 0.87);
        }
    }
    .dropdown-item.active {
        .country-label {
            color: #fff;
        }
    }
}
.trial-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4%;
    width: 170px;
    height: 40px;
    border-radius: 4px;
    background-color: #2d8dfc;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}
.trial-btn:hover {
    color: #ffffff;
    text-decoration: none;
}
.fa-times-thin:before {
    content: "\00d7";
    display: none;
}
.mobile-small-header {
    display: none;
}

@media only screen and (max-width: 767px) {
    .header-wrapper {
        -webkit-transition: left 0.3s ease-in-out;
        transition: left 0.3s ease-in-out;
        width: 200px;
        flex-direction: column;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        border-right: 1px solid #f1f6fa;
        background-color: white;
        color: var(--sidebar-color);
        overflow-y: auto;
        justify-content: flex-start;
        padding: 0;
        align-items: flex-start;
        .trial-btn {
            display: none;
        }
        .menu {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            justify-content: flex-start;

            .menu-group {
                flex-direction: column;
                height: auto;
                width: 100%;

                a.menu-item {
                    margin-left: 0px;
                    position: relative;
                    padding: 15px 0 15px 40px;
                    margin-top: 0;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 4px;
                        height: 100%;
                        background: transparent;
                        top: 0;
                        left: 0;
                        border-bottom: unset;
                        margin-top: unset;
                        margin-left: unset;
                        border-radius: unset;
                    }

                    &.active::after {
                        background: #347fbb;
                    }
                }
                a.active {
                    background-image: linear-gradient(to right, #f6faff 40%, white 100%, transparent 90%);
                }
            }
        }
        .header-logo-wrapper {
            width: 100%;
            height: 50px;
            justify-content: space-between;

            img {
                width: 50px;
                height: 24px;
                margin-left: 20px;
                cursor: pointer;
            }
            i {
                margin-right: 15px;
            }
        }
    }
    .hide-header {
        -webkit-transition: left 0.3s ease-in-out;
        transition: left 0.3s ease-in-out;
        left: -300px;
    }

    .fa-times-thin:before {
        content: "\00d7";
        display: inline-block;
    }
    .mobile-small-header {
        box-shadow: 0px 4px 20px rgba(201, 206, 213, 0.25);
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        height: 50px;
        width: 100%;
        background: white;
        z-index: 9998;
        align-items: center;
        justify-content: space-between;
        padding: 0 5%;
        img {
            margin-left: 20px;
            width: 50px;
        }
        .trial-btn-mobile {
            margin-left: 4%;
            padding: 7px 10px;
            border-radius: 4px;
            background-color: #2d8dfc;
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 0.5px;
            color: #ffffff;
            text-transform: uppercase;
            text-align: center;
            cursor: pointer;
        }
    }
}
.outline-none {
    outline: none;
}

.bi-chevron-down {
    font-weight: bolder;
    margin-left: 8px;
    margin-top: -1px;
    transition: all 300ms;

    &:hover {
        color: rgba(51, 51, 51, 0.87);
    }
}

.rotate-180 {
    transform: rotate(-180deg);
}

.features-link {
    &:hover {
        color: #333 !important;
    }
}

.features-dropdown-wrapper {
    z-index: 14;
    position: absolute;
    bottom: -70px;
    background: #fff;
    box-shadow: 0 4px 20px 0 rgba(201, 206, 213, 0.25);

    ul {
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;

        li {
            padding: 0.5rem 1rem;
            a {
                color: #333333;
                text-transform: capitalize;
                font-size: 0.9rem;
                font-weight: 400;

                &:hover {
                    text-decoration: none;
                    color: #333333 !important;
                }
            }
        }
    }
}

.features-list-mobile {
    display: none;

    ul {
        list-style: none;
        font-size: 0.8rem;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 0;

        li {
            padding: 0.3rem;

            a {
                color: #333333;
                display: block;
                padding-left: 10px;
                padding-right: 5px;
                text-transform: capitalize;
                font-size: 0.75rem;
                font-weight: 400;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover {
                    text-decoration: none;
                    color: #333333 !important;
                }
            }
        }
    }
}

.panel-collapse {
    max-height: 500px;
    overflow: hidden;
    transition: all 0.5s ease;
}
.panel-close {
    max-height: 0;
    display: none !important;
}

@media screen and (max-width: 767px) {
    .features-link {
        padding-bottom: 15px !important;
    }
    .features-list-mobile {
        display: block;
    }
    .features-dropdown-wrapper {
        display: none;
    }
}
